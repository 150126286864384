import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NotFoundView from '@/views/NotFoundView.vue'


import {
  HomeIcon,
  CloudIcon,
  ChatBubbleLeftRightIcon,
  PencilSquareIcon,
  ChartPieIcon,
  PresentationChartBarIcon,
  RectangleGroupIcon,
  TableCellsIcon
} from '@heroicons/vue/24/outline'

import ProfileView from '@/views/Auth/ProfileView.vue'

const ForumView = () => import('@/views/Iframes/ForumView.vue')
const QuestionsView = () => import('@/views/Questions/QuestionsView.vue')
const QuestionsAMView = () => import('@/views/Questions/QuestionsAMView.vue')
const QuestionsPMView = () => import('@/views/Questions/QuestionsPMView.vue')
const QuestionsQuartsView = () => import('@/views/Questions/QuestionsQuartsView.vue')
const OwnCloudView = () => import('@/views/Iframes/OwnCloudView.vue')
const ActivationView = () => import('@/views/Auth/ActivationView.vue')
const BarChartsView = () => import('@/views/Stats/BarChartsView.vue')
const InscriptionInspecteurView = () => import('@/views/Auth/InscriptionInspecteurView.vue')
const InscriptionFormateurView = () => import('@/views/Auth/InscriptionFormateurView.vue')
const InscriptionChefetablissementView = () => import('@/views/Auth/InscriptionChefetablissementView.vue')
const WordCloudView = () => import('@/views/Stats/WordCloudView.vue')
const DashboardView = () => import('@/views/Dashboard/DashboardView.vue')
const DashboardGlobalView = () => import('@/views/Dashboard/DashboardGlobalView.vue')
const DashboardUsersView = () => import('@/views/Dashboard/DashboardUsersView.vue')
const DashboardChartsView = () => import('@/views/Dashboard/DashboardChartsView.vue')
const ForgetPasswordView = () => import('@/views/Auth/ForgetPasswordView.vue')
const DashboardMailsView = () => import('@/views/Dashboard/DashboardMailsView.vue')
const ChangePasswordView = () => import('@/views/Auth/ChangePasswordView.vue')
const DashboardSentMailsView = () => import('@/views/Dashboard/DashboardSentMailsView.vue')
const AirtableView = () => import('@/views/Airtable/AirtableView.vue')


const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Accueil',
      component: HomeView,
      meta: { permission: "route.home",
        allowNavigation: true,
        navigationIcon: HomeIcon
      }
    },
    {
      path: '/cloud',
      name: 'Cloud',
      component: OwnCloudView,
      meta: { permission: "route.cloud",
        allowNavigation: true,
        navigationIcon: CloudIcon
      }
    },
    {
      path: '/forum',
      name: 'Forum',
      component: ForumView,
      meta: { permission: "route.forum",
        allowNavigation: true,
        navigationIcon: ChatBubbleLeftRightIcon
      }
    },
    // {
    //   path: '/quiz/:id',
    //   name: 'Quiz',
    //   component: QuestionsQuartsView,
    //   meta: { permission: "route.quiz",
    //     allowNavigation: false
    //   }
    // },
    {
      path: '/questions',
      name: 'Questions',
      component: QuestionsView,
      meta: { permission: "route.question",
        allowNavigation: true,
        navigationIcon: PencilSquareIcon
      }
    },
    {
      path: '/block/am/:id/',
      name: 'Questions Matin',
      component: QuestionsAMView,
      meta: { permission: "route.block.am",
        allowNavigation: false
      }
    },
    {
      path: '/block/pm/:id/',
      name: 'Questions Après-midi',
      component: QuestionsPMView,
      meta: { permission: "route.block.pm",
        allowNavigation: false
      }
    },
    {
      path: '/activations/:uuid',
      name: 'Réinitialisation mot de passe',
      component: ActivationView,
      meta: { permission: "route.activations",
        allowNavigation: false
      }
    },
    {
      path: '/forgetpwd/',
      name: 'Mot de passe oublié',
      component: ForgetPasswordView,
      meta: { permission: "route.forgetpwd",
        allowNavigation: false
      }
    },
    {
      path: '/reset/:uuid',
      name: 'Changement de mot de passe',
      component: ChangePasswordView,
      meta: { permission: "route.changepwd",
        allowNavigation: false
      }
    },
    {
      path: '/wordcloud',
      name: 'Nuage de mots',
      component: WordCloudView,
      meta: { permission: "route.wordcloud",
        allowNavigation: false,
        navigationIcon: ChartPieIcon
      }
    },
    {
      path: '/BarCharts',
      name: 'BarCharts',
      component: BarChartsView,
      meta: { permission: "route.charts",
        allowNavigation: false,
        navigationIcon: PresentationChartBarIcon
      }
    },
    {
      path: '/profile',
      name: 'Profil',
      component: ProfileView,
      meta: { permission: "route.profile",
        allowNavigation: false,
      }
    },
    {
      path: '/inscription/formateur',
      name: 'Inscription Formateur',
      component: InscriptionFormateurView,
      meta: { permission: "route.inscription.formateur",
        allowNavigation: false
      }
    },
    {
      path: '/inscription/inspecteur',
      name: 'Inscription Inspecteur',
      component: InscriptionInspecteurView,
      meta: { permission: "route.inscription.inspecteur",
        allowNavigation: false
      }
    },
    {
      path: '/inscription/chefetablissement',
      name: 'Inscription Chef d\'établissement',
      component: InscriptionChefetablissementView,
      meta: { permission: "route.inscription.chefetablissement",
        allowNavigation: false
      }
    },
    {
      path: '/dashboard',
      component: DashboardView,
      name: 'Dashboard',
      meta: { permission: "route.dashboard",
        allowNavigation: true,
        navigationIcon: RectangleGroupIcon
      },
      children: [
        {
          name: 'Dashboard',
          path: '',
          component: DashboardGlobalView,
        },
        {
          name: 'Utilisateurs',
          path: 'users',
          component: DashboardUsersView,
        },
        {
          name: 'Charts',
          path: 'charts',
          component: DashboardChartsView,
        }
        ,
        {
          name: 'Mails',
          path: 'mails',
          component: DashboardMailsView,
        }
        ,
        {
          name:'Mails envoyés',
          path: 'sentmails',
          component: DashboardSentMailsView,
        }
      ],
      
    },
    {
      path: '/airtable',
      component: AirtableView,
      name: 'Airtable',
      redirect: { name: 'Thématiques' },
      meta: {
        permission: "route.airtable",
        allowNavigation: true,
        navigationIcon: TableCellsIcon
      },
      children: [
        {
          path: 'thematiques',
          name: 'Thématiques',
          component: () => import('@/views/Airtable/TableView.vue'),
          redirect: { name: 'Climat' },
          meta: {
            permission: "route.airtable.thematiques",
            allowSubNavigation: true
          },
          children: [
            {
              name: 'Climat',
              path: 'climat',
              component: () => import('@/components/airtable/Tables/TableClimatComponent.vue'),
              meta: {
                permission: "route.airtable.thematiques.climat",
              },
            },
            {
              name: 'Stéréotype',
              path: 'stereotype',
              component: () => import('@/components/airtable/Tables/TableStereotypeComponent.vue'),
              meta: {
                permission: "route.airtable.thematiques.stereotype",
              },
            },
            {
              name: 'Coopération',
              path: 'cooperation',
              component: () => import('@/components/airtable/Tables/TableCooperationComponent.vue'),
              meta: {
                permission: "route.airtable.thematiques.cooperation",
              },
            },
            {
              name: 'Influence',
              path: 'influence',
              component: () => import('@/components/airtable/Tables/TableInfluenceComponent.vue'),
              meta: {
                permission: "route.airtable.thematiques.influence",
              },
            },
            {
              name: 'Eccipe',
              path: 'eccipe',
              component: () => import('@/components/airtable/Tables/TableEccipeComponent.vue'),
              meta: {
                permission: "route.airtable.thematiques.eccipe",
              },
            },
            {
              name: 'Feedback',
              path: 'feedback',
              component: () => import('@/components/airtable/Tables/TableFeedbackComponent.vue'),
              meta: {
                permission: "route.airtable.thematiques.feedback",
              },
            },
          ]
        },
        {
          path: 'publications',
          name: 'Vos publications',
          component: () => import('@/views/Airtable/PublishedView.vue'),
          meta: {
            permission: "route.airtable.publications",
            allowSubNavigation: true
          },
          
        },
        {
          path: 'draft',
          name: 'Vos Brouillons',
          component: () => import('@/views/Airtable/DraftView.vue'),
          meta: {
            permission: "route.airtable.publications",
            allowSubNavigation: true
          },
          
        },
        {
          path: 'editor/:id',
          name: 'Editeur',
          component: () => import('@/views/Airtable/EditorView.vue'),
          meta: {
            permission: "route.airtable.editor",
            allowSubNavigation: false
          },
        },
        {
          path: 'ressource/:id',
          name: 'Ressource',
          component: () => import('@/views/Airtable/RessourceView.vue'),
          meta: {
            permission: "route.airtable.ressource",
            allowSubNavigation: false
          },
        }
      ]
    },
    {
      // path: "*",
      path: "/:catchAll(.*)",
      redirect: "/404"
    },
    {
      // path: "*",
      path: "/404",
      name: "404",
      component: NotFoundView,
      meta: {
        allowNavigation: false
      }
    }
  ]
})



export default router